import React from 'react';
function SignInButton({handleLogin}) {
  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      className="tve-footer__right"
      onClick={handleLogin}
    >
      Sign In to TV Provider
    </a>
  );
}

export default SignInButton;
