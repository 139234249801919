import {createFetchService} from './fetch_utils';
import {Braze} from '../endpoints';
import AnalyticsAPI from '../apis/analytics_api';

const postJSON = createFetchService('Braze', {method: 'POST'});

export const updateSubscriptionGroup = async (data) => {
  const {email, affiliatesConsent} = data;
  const subscription_state = affiliatesConsent ? 'subscribed' : 'unsubscribed';
  const accessToken = process.env.BRAZE_SUBSCRIPTION_API_KEY;
  if (accessToken) {
    const identity = await AnalyticsAPI.getTrackingId();
    const body = {
      subscription_group_id: process.env.SUBSCRIPTION_GROUP_ID,
      subscription_state,
      external_id: identity.warehouse_id,
      email,
    };
    const opts = {
      body,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    };

    return postJSON(Braze.subscriptionGroup(), opts);
  }
  return Promise.reject(new Error('No accessToken passed to Braze API'));
};

export const setSubscriptionGroupByUsersTrack = async (data) => {
  const {email, affiliatesConsent} = data;
  const subscription_state = affiliatesConsent ? 'subscribed' : 'unsubscribed';
  const accessToken = process.env.BRAZE_SUBSCRIPTION_API_KEY;

  if (accessToken) {
    const identity = await AnalyticsAPI.getTrackingId();

    const body = {
      attributes: [
        {
          external_id: identity.warehouse_id,
          email,
          subscription_groups: [
            {
              subscription_group_id: process.env.SUBSCRIPTION_GROUP_ID,
              subscription_state,
            },
          ],
        },
      ],
    };

    const opts = {
      body,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    };
    return postJSON(Braze.usersTrack(), opts);
  }

  return Promise.reject(new Error('No accessToken passed to Braze API'));
};
