import {buildQueryString} from 'url-params-helper';
import {LOGO_SIZE} from './constants/logos';

// Note: We are intentionally looking inside `process.env` instead of saving an intermediate
// "constant" so that Babel can transform the environment variables inline for the correct
// environment when building the compiled JavaScript.

// Additional Note: Put the host() first then the rest in alphabetical order.
// This makes everything easier to find.

function isEmptyQuery(query) {
  return !Object.keys(query).length;
}

function urlWithQueryIfPresent(url, query) {
  if (isEmptyQuery(query)) {
    return url;
  }
  return `${url}?${buildQueryString(query)}`;
}

// Armchair is the backend storing user-written posts/tracks.
const Armchair = {
  host() {
    return process.env.ARMCHAIR_HOST || 'armchair.bleacherreport.com';
  },
  userPost(postId) {
    return `https://${Armchair.host()}/posts/${postId}`;
  },
};

// URLs using the `android-app://` protocol.
const AndroidApp = {
  protocolAndHost() {
    return 'android-app://com.bleacherreport.android.teamstream';
  },
  article(permalink) {
    return `${AndroidApp.protocolAndHost()}/http/bleacherreport.com/articles/${permalink}`;
  },
  teamstream(permalink) {
    return `${AndroidApp.protocolAndHost()}/teamstream/article/bleacherreport.com/articles/${permalink}`;
  },
};

// The Elixir-powered article content API.
const Articles = {
  host() {
    return process.env.ARTICLES_HOST || 'articles.bleacherreport.com';
  },
  articles(articleIds) {
    const ids = articleIds?.join(',');
    return `https://${Articles.host()}/articles/collection?ids=${ids}`;
  },
  pageArticle(articleID) {
    return `https://${Articles.host()}/articles/${articleID}`;
  },
  latestWritten(authorID) {
    return `https://${Articles.host()}/authors/${authorID}/most_recent`;
  },
  latestWrittenByUuid(uuid) {
    return `https://${Articles.host()}/authors/${uuid}/most_recent_articles`;
  },
  previewData(articleID) {
    return `https://${Articles.host()}/dashboard/articles/${articleID}`;
  },
  upcheck() {
    return `https://${Articles.host()}/up/elb`;
  },
};

// Services provided under our main domain name.
// Some are powered by legacy monolith application;
// some are redirected to Node Report backend.
const BR = {
  host() {
    return process.env.BR_HOST || 'bleacherreport.com';
  },
  article(currentPage) {
    return `https://${BR.host()}/articles/${currentPage}`;
  },
  baseURL() {
    return `https://${BR.host()}/`;
  },
  betsIframe(slug) {
    return `https://${BR.host()}/bets/iframe/${slug}`;
  },
  gamecast(slug) {
    return `https://${BR.host()}/game/${slug}`;
  },
  liveblogs(slug) {
    return `https://${BR.host()}/live-blogs/${slug}`;
  },
  myEvents() {
    return `https://${BR.host()}/my-events`;
  },
  recaptchaVerified(params) {
    return `https://${BR.host()}/recaptcha_verified?${buildQueryString(params)}`;
  },
  search(query = null) {
    // This endpoint (breport as of May 2016) works via both GET and POST
    if (query) {
      return `https://${BR.host()}/search?${buildQueryString({q: query})}`;
    }
    return `https://${BR.host()}/search`;
  },
  section(currentPage) {
    return `https://${BR.host()}/${currentPage}`;
  },
  sectionStreamApp(sectionSlug) {
    return `https://${BR.host()}/${sectionSlug}/stream/app`;
  },
  sendGrid() {
    return `http://${BR.host()}/api/sendgrid/download_email`;
  },
  slide(currentPage, slide) {
    return slide ? `${BR.article(currentPage)}#slide${slide}` : BR.article(currentPage);
  },
  stub(page) {
    return `https://${BR.host()}/post/${page.tag}/${page.id}`;
  },
  suggestionsSearch(query) {
    return `https://${BR.host()}/suggestions/search?${buildQueryString({q: query})}`;
  },
  support() {
    return `https://support.${BR.host()}`;
  },
  tagSuggestionsSearch(query) {
    return `https://${BR.host()}/suggestions/tags?${buildQueryString({q: query})}`;
  },
  teamSchedule(teamSlug) {
    return `https://${BR.host()}/${teamSlug}/schedule`;
  },
  twilioDownloadLink() {
    return `https://${BR.host()}/api/twilio/download_link`;
  },
  twilioGenericDownloadLink({phoneNumber}) {
    return `https://${BR.host()}/api/twilio/download_link_lp?${buildQueryString({
      to: phoneNumber,
    })}`;
  },
  video(currentPage) {
    return `https://${BR.host()}/videos/${currentPage}`;
  },
  uidcheck() {
    return `https://${BR.host()}/uidcheck`;
  },
  upcheck() {
    return `https://${BR.host()}/up/elb`;
  },
  userPost(id) {
    return `https://${BR.host()}/user_post/${id}`;
  },
};

const Braze = {
  host() {
    return process.env.BRAZE_HOST || 'rest.iad-03.braze.com';
  },
  subscriptionGroup() {
    return `https://${Braze.host()}/subscription/status/set`;
  },
  usersTrack() {
    return `https://${Braze.host()}/users/track`;
  },
};

const BRLive = {
  host() {
    return process.env.BRLIVE_HOST || 'brlive.api.istreamplanet.net';
  },
  anonymousRegistration() {
    return `https://${BRLive.host()}/oam/v2/anonymous/register`;
  },
  anonymousLogin() {
    return `https://${BRLive.host()}/oam/v2/anonymous/login`;
  },
  videoEntities(assetId) {
    return `https://${BRLive.host()}/ocm/v2/entities/${assetId}?include=assets`;
  },
  userAccountEntitlement(userID) {
    return `https://${BRLive.host()}/oem/v1/user/accounts/${userID}/entitlement?tokentype=turner-akamai`;
  },
};

// Third-party service which provides device detection and redirection to appropriate links in App Stores.
const BranchDotIo = {
  userPostDeeplink(userPostId) {
    return `https://br.app.link?$deeplink_path=user_post/bleacherreport.com/user_post/${userPostId}`;
  },
};

// Files and things! Our Akamai fronted, Amazon S3 hosted, CDN
const CDN = {
  host() {
    return process.env.CDN_HOST || 'cdn.bleacherreport.net';
  },
  contentUnavailableImage(number) {
    return `https://${CDN.host()}/images/unavailable_content/${number}.jpg`;
  },
  desktopNavLink() {
    return `https://${CDN.host()}/page_cache/desktop_navigation_link_json.json`;
  },
  images() {
    return `https://${CDN.host()}/images`;
  },
  nav(countryCode) {
    // Use US Nav if no country code is set or value is US
    const isUs = !countryCode || countryCode === 'USA';
    const navCountryCode = isUs ? '' : '_uk';

    return `https://${CDN.host()}/page_cache/desktop_navigation_link_json${navCountryCode}.json`;
  },
};

const Cloudinary = {
  cdn() {
    return `https://media.bleacherreport.com`;
  },
  transform(params = {}) {
    // eslint-disable-next-line id-length
    const {w, h} = params;
    // Auto format and auto quality are recommended and yield the best results after testing
    // https://cloudinary.com/documentation/image_transformations#img_trans_overview
    return `f_auto,w_${w}${h && h > 0 ? `,h_${h}` : ''},q_auto,c_fill`;
  },
  cms(imagePath = '', urlParams) {
    return `${Cloudinary.cdn()}/${Cloudinary.transform(urlParams)}/br-cms/${imagePath}`;
  },
  images(imagePath = '', urlParams) {
    return `${Cloudinary.cdn()}/${Cloudinary.transform(urlParams)}/br-img-images/${imagePath}`;
  },
  teamLogo(size = LOGO_SIZE.Large) {
    return `${Cloudinary.cdn()}/image/upload/${size}/team_logos`;
  },
  article(imagePath = '', urlParams) {
    return `${Cloudinary.cdn()}/${Cloudinary.transform(urlParams)}/br-img-article/${imagePath}`;
  },
  slides(imagePath = '', urlParams) {
    return `${Cloudinary.cdn()}/${Cloudinary.transform(urlParams)}/br-img-slides/${imagePath}`;
  },
};

const Embeder = {
  host() {
    return process.env.EMBEDER_HOST || 'embeder.bleacherreport.com';
  },
  oembed(url) {
    return `https://${Embeder.host()}/oembed?${buildQueryString({url})}`;
  },
};

const Faust = {
  host() {
    return process.env.FAUST_HOST || 'faust.bleacherreport.com';
  },
  articleMeta(articleID) {
    return `https://${Faust.host()}/article-metadata/${articleID}`;
  },
  playlistMetadata(playlistId) {
    return `https://${Faust.host()}/playlist-metadata/${playlistId}`;
  },
  tags(slugsOrIds, ordered) {
    return ordered
      ? `https://${Faust.host()}/tags/${slugsOrIds}?maintain_order=true`
      : `https://${Faust.host()}/tags/${slugsOrIds}`;
  },
  upcheck() {
    return `https://${Faust.host()}/up/elb`;
  },
};

// Our Elixir-based User service. Handles authentication and user information.
const Gatekeeper = {
  host() {
    return process.env.GATEKEEPER_HOST || 'gatekeeper.bleacherreport.com';
  },
  authorizeFacebookUser() {
    return `https://${Gatekeeper.host()}/facebook/authorize`;
  },
  authorizePhone(userID) {
    return userID
      ? `https://${Gatekeeper.host()}/users/${userID}/phone/authorize`
      : `https://${Gatekeeper.host()}/phone/authorize`;
  },
  confirmResend() {
    return `https://${Gatekeeper.host()}/confirmation/resend`;
  },
  confirmUser(params) {
    return `https://${Gatekeeper.host()}/confirmation?${buildQueryString(params)}`;
  },
  createUser() {
    return `https://${Gatekeeper.host()}/account`;
  },
  linkFacebookUser() {
    return `https://${Gatekeeper.host()}/facebook/link`;
  },
  oauth() {
    return `https://${Gatekeeper.host()}/oauth2/token`;
  },
  passwordResets() {
    return `https://${Gatekeeper.host()}/password_resets`;
  },
  passwordResetsWithToken(token) {
    return `https://${Gatekeeper.host()}/password_resets/${token}`;
  },
  public_key() {
    return `https://${Gatekeeper.host()}/public_key`;
  },
  registerPhone() {
    return `https://${Gatekeeper.host()}/phone/register`;
  },
  upcheck() {
    return `https://${Gatekeeper.host()}/up/elb`;
  },
  user(userId, IPAddress) {
    const queryParams = IPAddress ? `?user_ip_address=${IPAddress}` : '';
    return `https://${Gatekeeper.host()}/users/${userId}${queryParams}`;
  },
  userEmail(userId) {
    return `https://${Gatekeeper.host()}/users/${userId}/email`;
  },
  userPrivate(userId) {
    return `https://${Gatekeeper.host()}/users/${userId}/private`;
  },
  userProfile(id) {
    return `https://${Gatekeeper.host()}/users/${id}/profile`;
  },
  userTags(userId) {
    return `https://${Gatekeeper.host()}/users/${userId}/user_tags`;
  },
  usernameCheck(username) {
    let endpoint;
    if (username) {
      endpoint = `https://${Gatekeeper.host()}/usernames/${username}/validate`;
    }
    return endpoint; // fetchJSON will error if undefined. Yay logging.
  },
  usernameRegister() {
    return `https://${Gatekeeper.host()}/usernames/reserve`;
  },
  validateName(name, value) {
    return `https://${Gatekeeper.host()}/usernames/validate_names?${name}=${value}`;
  },
  verifyPhone(userID) {
    return userID
      ? `https://${Gatekeeper.host()}/users/${userID}/phone/verify`
      : `https://${Gatekeeper.host()}/phone/verify`;
  },
  verifyRegistrationCode() {
    return `https://${Gatekeeper.host()}/oauth2/verify`;
  },
};

const House = {
  host() {
    return process.env.HOUSE_HOST || 'house.bleacherreport.com';
  },
  bets(betTags, timezone) {
    return `https://${House.host()}/api/bets?tags=${betTags}&tz=${timezone}`;
  },
  betTrack(betTrackId) {
    return `https://${House.host()}/api/bettracks/${betTrackId}`;
  },
};

// URLs with the protocol `ios-app://`.
const IosApp = {
  protocolAndHost() {
    return 'ios-app://418075935/';
  },
  article(permalink) {
    return `${IosApp.protocolAndHost()}http/bleacherreport.com/articles/${permalink}`;
  },
};

// The "LAYout SERvice". Now in Elixir (which runs on Erlang's BEAM VM, natch).
// Does a bit more than layout info now, will eventually be the "consumer" API
const Layser = {
  host() {
    return process.env.LAYSER_HOST || 'layserbeam-cached.bleacherreport.com';
  },
  aggregateCountsForUserPost(postId) {
    return `https://${Layser.host()}/aggregate_counts?url=${BR.userPost(postId)}`;
  },
  alerts(tags) {
    return `https://${Layser.host()}/push_notifications/?tags=${tags}`;
  },
  api() {
    return `https://${Layser.host()}/api`;
  },
  article(articleID) {
    return `https://${Layser.host()}/articles/${articleID}`;
  },
  gamecast(slug) {
    return `https://${Layser.host()}/gamecast/${slug}?appversion=100.0`;
  },
  gamecastPlaylist(path) {
    return `https://${Layser.host()}${path}`;
  },
  liveGameEvents(path) {
    return `https://${Layser.host()}${path}`;
  },
  liveVideoEvent(id) {
    return `https://${Layser.host()}/live_events/${id}?store=stripe`;
  },
  myEvents() {
    return `https://${Layser.host()}/my_events`;
  },
  newsletterSubscription() {
    return `https://${Layser.host()}/newsletter_subscription`;
  },
  pageSection(section) {
    return `https://${Layser.host()}/api/page/${section}`;
  },
  playlist(tag, limit = 60) {
    return `${Layser.api()}/djay/${tag}?limit=${limit}`;
  },
  playlistSectionTrending(section, query = {}) {
    const sectionTrendingUrl = Layser.playlist(`${section}-trending`);
    return urlWithQueryIfPresent(sectionTrendingUrl, query);
  },
  recommendedArticles(articleID) {
    return `https://${Layser.host()}/articles/${articleID}/article_recommendations`;
  },
  reverseLookup(url) {
    return `https://${Layser.host()}/djay/content?url=${url}`;
  },
  section(section, userTags) {
    return `${Layser.pageSection(section)}/?tags=${userTags.join()}`;
  },
  social() {
    return {
      reactionAggregatesAnon: `https://${Layser.host()}/social/reaction/aggregates/anon`,
    };
  },
  stub(url) {
    return `https://${Layser.host()}/stubs/${url}`;
  },
  teamStreamSingleTag(tag, query = {}) {
    const teamStreamSingleTagUrl = `https://${Layser.api()}/team_stream_single/${tag}`;
    return urlWithQueryIfPresent(teamStreamSingleTagUrl, query);
  },
  teamStreamTag(tag, query = {}) {
    const teamStreamTagUrl = `https://${Layser.api()}/team_stream/${tag}`;
    return urlWithQueryIfPresent(teamStreamTagUrl, query);
  },
  trendingArticles() {
    return `https://${Layser.host()}/trending_articles`;
  },
  trendingVideos() {
    return `https://${Layser.host()}/trending_videos`;
  },
  upcheck() {
    return `https://${Layser.host()}/up/elb`;
  },
  videoRecommendations(articleId) {
    return `https://${Layser.host()}/articles/${articleId}/video_recommendations`;
  },
};

const Lightning = {
  host() {
    return 'lightning.bleacherreport.com';
  },
  determineEnv() {
    return process.env.RELEASE_ENV === 'production' ? 'release' : 'pre';
  },
  psmVersionManager() {
    return `https://${Lightning.host()}/cdp/psm/brands/bleacherreport/web/${Lightning.determineEnv()}/psm.min.js`;
  },
};

const NGTV = {
  blackout(appId, feed, titleId) {
    return `https://blackout.ngtv.io/shouldBlackout?appId=${appId}&feed=${feed}&titleId=${titleId}`;
  },
  host() {
    return process.env.NGTV_HOST || 'token.ngtv.io';
  },
  token() {
    return `https://${NGTV.host()}/token/token_spe_catch`;
  },
};

const OneTrust = {
  scriptUrl: 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
};

// Provides user identification tokens for analytics events via mParticle
const Portmeirion = {
  host() {
    return process.env.PORTMEIRION_HOST || 'portmeirion.bleacherreport.com';
  },
  facebookLogin(warehouseId, facebookId) {
    return `https://${Portmeirion.host()}/api/facebook_login/${warehouseId}/${facebookId}`;
  },
  identify(uuid) {
    const loginId = (uuid && `?login_id=${uuid}`) || '';
    return `https://${Portmeirion.host()}/api/identify${loginId}`;
  },
  login(warehouseId, loginId) {
    return `https://${Portmeirion.host()}/api/login/${warehouseId}/${loginId}`;
  },
  move(deviceId, warehouseId) {
    return `https://${Portmeirion.host()}/api/device/move/${deviceId}/${warehouseId}`;
  },
};

// Branch.io promo links
const Promos = {
  host() {
    return process.env.BR_APP_LINK || 'br.app.link';
  },
  teamstream(params) {
    // We can't use buildQueryString because it encodes $deeplink_path messing up deeplinking in the device (tested)
    const deeplink = params.deeplink_path ? `$deeplink_path=${params.deeplink_path}` : '';
    const utmParams = params.utmParams ? params.utmParams : '';
    const trackId = params.trackId ? `&trackId=${params.trackId}` : '';
    const playlistName = params.playlistName ? `&playlistName=${params.playlistName}` : '';
    const contentHash = params.contentHash ? `&contentHash=${params.contentHash}` : '';

    return `https://${Promos.host()}?${deeplink}&channel=${params.channel}&feature=${
      params.feature
    }${utmParams + trackId + playlistName + contentHash}`;
  },
};

// Where we get what Sports and Games are playing.
const Scores = {
  host() {
    return process.env.SCORES_HOST || 'scores2.bleacherreport.com';
  },
  fantasyPlayers(device_id, teams, league = 'nba') {
    return `https://${Scores.host()}/api/yahoo_fantasy/players.json?${buildQueryString({
      device_id,
      teams,
      league,
    })}`;
  },
  fantasyTeams(code, device_id, league = 'nba') {
    return `https://${Scores.host()}/api/v1/fantasy/teams?${buildQueryString({
      code,
      device_id,
      league,
    })}`;
  },
  gamesByTeam(teamSlug) {
    return `https://${Scores.host()}/api/v1/scores/team/${teamSlug}`;
  },
  path(path) {
    return `https://${Scores.host()}/api/v1${path}`;
  },
  upcheck() {
    return `https://${Scores.host()}/up/elb`;
  },
};

// Our old "CDN" (actually just static assets from the monolith servers).
// It can also point to locally-hosted assets.
// Really most stuff here should be moved to the CDN.
const Static = {
  host() {
    return process.env.STATIC_ASSETS || '';
  },
  assets(path = '') {
    const prefix = process.env.STATIC_ASSETS ? `https://${process.env.STATIC_ASSETS}` : '';
    return prefix + path;
  },
  flags() {
    return {
      usa: {
        gray: `${Static.images()}molecules/nav/flagUSGray.png`,
        color: `${Static.images()}molecules/nav/flagUS.png`,
      },
      gbr: {
        gray: `${Static.images()}molecules/nav/flagUKGray.png`,
        color: `${Static.images()}molecules/nav/flagUK.png`,
      },
    };
  },
  images() {
    const host = Static.host();
    return host === '' ? '/img/' : `https://${host}/img/`;
  },
};

const Statmilk = {
  host() {
    return process.env.STAT_MILK_HOST || 'statmilk.bleacherreport.com';
  },
  schedules(league, date, context) {
    const appversion = '500.0';
    return `https://${Statmilk.host()}/api/scores/schedules?${buildQueryString({
      date,
      league,
      appversion,
      context,
    })}`;
  },
  scoresCarousel(tz, league, team, carousel_context) {
    // This endpoint is going to be used only for the carousel in section pages
    const appversion = '500.0';
    // To get NHL gamecast data we need to pass in the appversion since NHL was added after app version 10.
    // Web versions are less sensitive than the app and it is deployed a lot more frequently thus we can use an arbitrary high number.
    return `https://${Statmilk.host()}/api/scores/carousel?${buildQueryString({
      league,
      team,
      carousel_context,
      tz,
      appversion,
    })}`;
  },
  schedulesByTeam(teamSlug) {
    return `https://${Statmilk.host()}/api/schedules/${teamSlug}`;
  },
};

// Service to display AMP-tailored content.
const Syndication = {
  host() {
    return process.env.SYNDICATION_HOST || 'syndication.bleacherreport.com';
  },
  article(articlePermalink) {
    return `https://${Syndication.host()}/amp/${articlePermalink}.amp.html`;
  },
  liveblog(slug) {
    return `https://${Syndication.host()}/amp/live-blogs/${slug}`;
  },
  post(section, stubId) {
    return `https://${Syndication.host()}/amp/post/${section}/${stubId}`;
  },
  section(section) {
    return `https://${Syndication.host()}/amp/${section}`;
  },
};

// URLs using the `teamstream://` protocol.
const Teamstream = {
  protocol() {
    return 'teamstream://';
  },
  article(permalink) {
    return `${Teamstream.protocol()}article/bleacherreport.com/articles/${permalink}`;
  },
  stream(section) {
    return `${Teamstream.protocol()}stream/${section}?st=p`;
  },
};

// For the Careers page (by department).
// The end point actually gets ALL jobs, but... oh well.
// Overkill right now but keeping these a) consistient b) easy to update
const JobScore = {
  host() {
    return 'www.jobscore.com';
  },
  allJobs() {
    return `https://${JobScore.host()}/jobs/bleacherreport/feed.json`;
  },
};

const ENV_DEV = 'dev';
const ENV_PROD = 'prod';
const ENV_PRODUCTION = 'production';
const ENV_QA = 'qa';
function determineTealiumEnv() {
  if (process.env.TEALIUM_ENV) {
    return process.env.TEALIUM_ENV;
  }
  if (process.env.NODE_ENV === ENV_PRODUCTION) {
    if (process.env.RELEASE_ENV === ENV_PRODUCTION) {
      return ENV_PROD;
    }
    return ENV_QA;
  }
  return ENV_DEV;
}

const Tealium = {
  scriptSrc() {
    return `https://tags.tiqcdn.com/utag/bleacherreport/main/${determineTealiumEnv()}/utag.js`;
  },
};

// Bleacher Report - Adobe Launch Guide
// https://docsprod.turner.com/pages/viewpage.action?spaceKey=ADBPDEV&title=Bleacher+Report+-+Adobe+Launch+Guide
const AdobeLaunch = {
  scriptSrc() {
    switch (process.env.RELEASE_ENV) {
      case 'production':
        return 'https://lightning.bleacherreport.com/launch/7be62238e4c3/a65fb0443535/launch-0120d9dbfb13.min.js';
      case 'staging':
        return 'https://lightning.bleacherreport.com/launch/7be62238e4c3/a65fb0443535/launch-fd4e1cc08a93-staging.min.js';
      default:
        // Dev 1 (therer are two dev scripts available in Adobe Launh currently)
        return 'https://assets.adobedtm.com/7be62238e4c3/a65fb0443535/launch-e609a06c1c02-development.min.js';
    }
  },
};

const Vid = {
  host() {
    return process.env.VID_HOST || 'vid.bleacherreport.com';
  },
  videos(videoId) {
    return `https://${Vid.host()}/videos/${videoId}`;
  },
};

const WarnerMedia = {
  privacy() {
    return 'https://www.warnermediaprivacy.com/policycenter/b2c/WM/';
  },
};

const Dalton = {
  host() {
    return process.env.DALTON_HOST || 'audience.bleacherreport.com';
  },
  multitenantHost() {
    return process.env.DALTON_MULTITENANT_HOST || 'daltonmt1.identityservices.io';
  },
  auth() {
    return `https://${Dalton.host()}/core/api/1/gatekeeper/auth`;
  },
  emails() {
    return `https://${Dalton.host()}/core/api/1/user/emails`;
  },
  chargeUser() {
    return `https://${Dalton.host()}/gizmo/api/1/wingman/purchase`;
  },
  products() {
    return `https://${Dalton.host()}/gizmo/api/1/wingman/products`;
  },
  entitlements() {
    return `https://${Dalton.host()}/regwall/api/1/regwall/preauthorize`;
  },
  preAuthToken() {
    return `https://${Dalton.multitenantHost()}/strapii/api/1/authz/token`;
  },
  userProfile() {
    return `https://${Dalton.host()}/core/api/1/user`;
  },
};

const Pitboss = {
  host() {
    return process.env.PITBOSS_HOST || 'pitboss.bleacherreport.com';
  },
  polls(pollId) {
    return `https://${Pitboss.host()}/api/polls/${pollId}`;
  },
};

const LiveRamp = {
  host() {
    return process.env.LIVERAMP_HOST || 'idsync.rlcdn.com';
  },
  userSyncPixel(partnerId) {
    return `https://${LiveRamp.host()}/712551.gif?partner_uid=${partnerId}`;
  },
};

const GSP = {
  host() {
    return process.env.GSP_FEDERATED_HOST || 'sports-federated-api-gw.wmsports.io';
  },
  federated() {
    return `https://${GSP.host()}/graphql`;
  },
};

export {
  AdobeLaunch,
  AndroidApp,
  Armchair,
  Articles,
  BR,
  BranchDotIo,
  Braze,
  BRLive,
  CDN,
  Cloudinary,
  Dalton,
  Embeder,
  Faust,
  Gatekeeper,
  GSP,
  House,
  IosApp,
  JobScore,
  Layser,
  Lightning,
  LiveRamp,
  NGTV,
  OneTrust,
  Pitboss,
  Portmeirion,
  Promos,
  Scores,
  Static,
  Statmilk,
  Syndication,
  Tealium,
  Teamstream,
  Vid,
  WarnerMedia,
};
