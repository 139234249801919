// Desktop Navbar Dropdown for top level navigation

import React from 'react';
import {NavLink} from '../../Shared/NavLink/NavLink';
import {NavBarLink} from '../types';
import {Variant} from '../../Shared/Typography/Typography';

export interface NavBarDropDownProps {
  links?: NavBarLink[];
  wunderkindItem?: boolean;
}

export const NavBarDropdown: React.FC<NavBarDropDownProps> = ({links, wunderkindItem}) => {
  return (
    <div className="navBarDropdown">
      {links && (
        <div className="navBarDropdown__grid">
          {links.map((link) => (
            <NavLink
              href={link.href}
              key={link.title}
              variant={Variant.H3}
              className="navBarDropdown__link"
            >
              {link.title}
            </NavLink>
          ))}
        </div>
      )}

      {wunderkindItem && (
        <div className="navBarDropdown__verticalRule">
          <div className="navBarDropdown__wunderkindPlaceholder" />
        </div>
      )}
    </div>
  );
};
