import React, {useState} from 'react';
import {DefaultDropdown, NavBarLink, Navigation} from '../types';
import {UserType} from '../../types';
import {Position, Drawer} from '../../Shared/Drawer/Drawer';
import {MobileSideSubMenu} from './MobileSideSubMenu/MobileSideSubMenu';
import {MobileSideMenu} from './MobileSideMenu/MobileSideMenu';
import {MobileSubNavBar} from './MobileSubNavBar/MobileSubNavBar';
import {AccountSideMenu} from '../AccountSideMenu/AccountSideMenu';
import {BrLogo, DefaultUserIcon, HamburgerIcon, CloseIcon} from '../../Icons';

export interface MobileNavBarProps {
  navigation?: Navigation;
  userType: UserType;
  profileId?: Number;
  path: string[];
}

export const MobileNavBar: React.FC<MobileNavBarProps> = ({
  navigation,
  userType,
  profileId,
  path,
}) => {
  const {mainNavigation, subNavigation} = navigation;

  const [showMainMenu, setShowMainMenu] = useState(false);
  const [subMenu, setSubMenu] = useState(null);
  const [showAccountMenu, setShowAccountMenu] = useState(false);

  function handleAccountMenuClick() {
    setShowAccountMenu((showAccountMenu) => !showAccountMenu);
    setShowMainMenu(false);
  }

  function handleMainMenuClose() {
    setShowMainMenu(false);
    setSubMenu(null);
  }

  function handleMainMenuOptionClick(link: NavBarLink) {
    if (link?.children) {
      setSubMenu(link);
    }
  }

  function handleAccountClose(event: MouseEvent | KeyboardEvent) {
    event.stopPropagation();
    setShowAccountMenu(false);
  }

  return (
    <div className="mobileNavBar">
      <MobileHeader
        // eslint-disable-next-line react/jsx-no-bind
        onMenuClick={() => setShowMainMenu(!showMainMenu)}
        isOpen={showMainMenu}
        onAccountMenuClick={handleAccountMenuClick}
      />

      <div className="mobileNavBar__drawerContainer">
        <Drawer
          open={!!showMainMenu}
          position={Position.Left}
          onClose={handleMainMenuClose}
          disableBodyScroll={true}
        >
          <MobileSideMenu
            links={mainNavigation?.links}
            onClick={handleMainMenuOptionClick}
            wunderkindItem={false}
          />

          <Drawer
            open={!!subMenu}
            position={Position.Left}
            // eslint-disable-next-line react/jsx-no-bind
            onClose={() => setSubMenu(null)}
            hideBackdrop={true}
          >
            <MobileSideSubMenu
              title={subMenu?.title}
              links={(subMenu?.children as DefaultDropdown)?.links}
              // eslint-disable-next-line react/jsx-no-bind
              onBackButtonClick={() => setSubMenu(null)}
            />
          </Drawer>
        </Drawer>

        <Drawer
          open={showAccountMenu}
          position={Position.Right}
          disableBodyScroll={true}
          onClose={handleAccountClose}
        >
          <div className="mobileNavBar__accountMenuContainer">
            <AccountSideMenu userType={userType} profileId={profileId} />
          </div>
        </Drawer>
      </div>

      {subNavigation && (
        <MobileSubNavBar
          href={subNavigation.href}
          title={subNavigation.title}
          links={subNavigation.links}
          value={path[1]}
        />
      )}
    </div>
  );
};

export interface MobileHeaderProps {
  onMenuClick?: () => void;
  onAccountMenuClick?: () => void;
  isOpen: Boolean;
}

export const MobileHeader: React.FC<MobileHeaderProps> = ({
  isOpen,
  onMenuClick,
  onAccountMenuClick,
}) => {
  return (
    <div className="mobileHeader">
      <button className="mobileHeader__menuButton" onClick={onMenuClick}>
        {isOpen ? <CloseIcon /> : <HamburgerIcon />}
      </button>

      <a className="mobileHeader__brLogo" href="/">
        <BrLogo />
      </a>

      <button className="mobileHeader__userButton" onMouseUp={onAccountMenuClick}>
        <DefaultUserIcon />
      </button>
    </div>
  );
};
