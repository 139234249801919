// eslint-disable-next-line no-unused-vars
import {useCallback, useEffect, useState} from 'react';
import {connect} from 'react-redux';

import logger from '../../logger';
import {BR, Lightning, OneTrust, Static} from '../../endpoints';
import {setTagManagerLoadRules} from '../../actions/appActions';
import {ui} from '../../selectors/topLevelSelectors';

const userConsentScript = Static.assets('/vendor/wm/userconsent-iab.4.0.8.min.js');
const cookieDomain = `.${BR.host()}`;
const psmEnv = process.env.PRISM_ENV || 'dev';
const brand = 'Bleacher Report';
const psmAppId = process.env.PRISM_APP_ID || '5e97220f1c9d440000f034e2';

/**
 * This function maps user consent values to appropriate tagManager load rules
 * @param {{}} consentState
 * @param {string} region
 * @return {{}}
 */
const determineLoadRulesBasedOnConsent = () => {
  const isInUserConsentState = globalThis.WM?.UserConsent.inUserConsentState;
  return {
    fire_advertising: isInUserConsentState(['ads-contextual', 'ads-person', 'measure-ads']),
    fire_performance: isInUserConsentState([
      'measure-content',
      'measure-market',
      'product-develop',
    ]),
    fire_personalization: isInUserConsentState(['ads-person-prof', 'content-person-prof']),
    fire_strictly_needed: isInUserConsentState([
      'product-security',
      'deliver-content',
      'combine-data',
      'link-devices',
      'id-devices',
    ]),
    fire_vendor: isInUserConsentState(['gpp', 'iab', 'vendor']),
  };
};

const UserConsent = ({
  addScript,
  countryCode,
  disableScript,
  oneTrust,
  setLoadRules,
  stateCode,
}) => {
  const [scriptsLoaded, setScriptsLoaded] = useState(false);
  const [loadRulesUpdated, setLoadRulesUpdated] = useState(false);
  const loadPrism = useCallback(
    () =>
      addScript({
        key: 'psmManager',
        src: Lightning.psmVersionManager(),
        async: true,
      }),
    [addScript]
  );

  const loadUserConsentScript = useCallback(
    () =>
      addScript({
        key: 'userConsent',
        src: userConsentScript,
        onLoad: () => {
          globalThis.WM.UserConsent.init({
            brand,
            cookieDomain,
            countryCode,
            stateCode,
            domId: oneTrust.domainScript || 'be71acc0-d805-4c8f-a50e-e29974a22fb1-test',
            psmAppId,
            psmEnv,
            src: oneTrust.scriptUrl || OneTrust.scriptUrl,
          });
          setScriptsLoaded(true);
        },
      }),
    [addScript, countryCode, oneTrust.domainScript, oneTrust.scriptUrl, stateCode]
  );

  // Load all required scripts
  useEffect(() => {
    if (!disableScript && !scriptsLoaded && countryCode) {
      loadPrism()
        .then(loadUserConsentScript)
        .then(() => logger.info('User consent & Prism loaded!'))
        .catch((err) => logger.warn(`User consent & Prism failed to load - ${err}`));
    }
  }, [scriptsLoaded, disableScript, loadPrism, loadUserConsentScript, countryCode]);

  // Wait for scripts to load and then update TagManager load rules
  useEffect(() => {
    if (scriptsLoaded && !loadRulesUpdated) {
      const {getConsentState} = globalThis.WM.UserConsent;
      setLoadRules(determineLoadRulesBasedOnConsent(getConsentState()));
      setLoadRulesUpdated(true);
      logger.info('TagManager loadrules updated based on consent!');
    }
  }, [loadRulesUpdated, scriptsLoaded, setLoadRules]);

  return false;
};

const mapStateToProps = (state) => ({
  disableScript: ui(state).disableUserConsentScript,
  countryCode: ui(state).countryCode,
  stateCode: ui(state).regionCode,
});

const mapDispatchToProps = (dispatch) => ({
  setLoadRules: (loadRules) => dispatch(setTagManagerLoadRules(loadRules)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserConsent);
