import {createSelector} from 'reselect';
import {playlists, tracks, tags, page, sections, ui, user} from './topLevelSelectors';
import ImagesAPI from '../apis/images_api';
import {isGameStream} from '../helpers/streamHelpers';
import {contentTypes} from '../constants/contentTypes';

let counter;

const getTracksFromPlaylist = (tracks) => {
  return (id) => {
    return tracks[id];
  };
};

const PROMO_TRACK = {
  content_type: 'tickets_promo',
};

const AD_TRACK = {
  content_type: 'ad',
};

function generatePlaceholderId() {
  return `placeholder-${counter++}`;
}

function spliceInAdsAndPromos(array, track, index) {
  const arrayLength = array.length;
  // Ad after two tracks then every 4
  if ((index + 2) % 4 === 0) {
    const adObject = {
      ...AD_TRACK,
      id: generatePlaceholderId(),
    };
    return array.concat(adObject, track);
  }
  if (arrayLength === 3) {
    const promoObject = {
      ...PROMO_TRACK,
      id: 'ticketsPromo',
    };
    return array.concat(promoObject, track);
  }
  return array.concat(track);
}

function removeUnsupportedTracks(track) {
  // Filter out tracks that are not supported on web so the ad schema is not affected
  return ![contentTypes.POLL, contentTypes.BET_TRACK, contentTypes.USER_TEXT].includes(
    track.content_type
  );
}

const mapTagToList = (tag, currentSection, playlists, tracks, tags, section) => {
  const name = tag === 'all' ? `${currentSection}-${tag}` : tag;
  counter = 0;

  if (playlists[name] && Array.isArray(playlists[name])) {
    if (section && (isGameStream(section.layout) || section.format === 'stream')) {
      return {
        tracks: playlists[name]
          .map(getTracksFromPlaylist(tracks))
          .filter(removeUnsupportedTracks)
          .reduce(spliceInAdsAndPromos, []),
        info: tags[name],
      };
    }
    return {
      tracks: playlists[name].map(getTracksFromPlaylist(tracks)),
      info: tags[name],
    };
  }
  return {
    info: tags[name],
    url: playlists[name],
  };
};

export const selectPageId = createSelector([page], (page) => page.id);
export const selectTeamLeague = createSelector([page], (page) =>
  page.league ? page.league.replace('_', '') : ''
);
export const selectTeamStreamSelection = createSelector([ui], (ui) => ui.teamStreamSelected);
export const selectTeamStreamLimit = createSelector([ui], (ui) => ui.teamStreamLimit);
export const selectTrendingLimit = createSelector([ui], (ui) => ui.trendingLimit);

export const selectSection = createSelector(
  [selectPageId, sections],
  (id, sections) => sections[id] || {tags: []}
);
export const selectWidget = createSelector([sections], (sections) => sections.widget || false);
export const selectSectionFormat = createSelector(selectSection, (section) => section.format);
export const selectEditorialState = createSelector(
  selectSectionFormat,
  (format) => format === 'editorial'
);
export const selectSingleStreamState = createSelector(
  selectSectionFormat,
  (format) => format === 'stream'
);

export const selectUserTags = createSelector([user], (user) => user.tags || user.default_tags);

export const selectStreamLimits = createSelector(
  [selectTeamStreamLimit, selectTrendingLimit],
  (tsLimit, trendingLimit) => {
    return {tsLimit, trendingLimit};
  }
);

export const selectPageTagIds = createSelector(
  [selectPageId, selectSection, selectUserTags],
  (pageId, section, userTags) => {
    if (pageId === 'front-page' || pageId === 'uk') {
      return [...(section.tags || []), ...userTags];
    }
    return section.tags || [];
  }
);
export const selectPageTags = createSelector([selectPageTagIds, tags], (tagList, allTags) => {
  const tagsArray = Object.values(allTags);
  return tagList.reduce((tags, tagSlugOrId) => {
    // Filter and map tagList to allTags
    // This ensures tags exist before mapping
    const tag = allTags[tagSlugOrId] || tagsArray.find((tagObject) => tagObject.id === tagSlugOrId);
    if (tag) {
      tags.push(tag);
    }
    return tags;
  }, []);
});

export const selectCurrentTeamStream = createSelector(
  [selectTeamStreamSelection, selectSection, selectPageId],
  (selectedTeamStream, section, pageId) => {
    let current = selectedTeamStream;
    if (!selectedTeamStream) {
      current =
        section &&
        (section.format === 'stream' || (section.tags && section.tags.length === 1)) &&
        pageId !== 'uk' &&
        pageId !== 'front-page'
          ? pageId
          : 'all';
    }
    return current;
  }
);
export const selectStreams = createSelector(
  [selectSection, selectPageId, playlists, tracks, tags],
  (section, pageId, playlists, tracks, tags) => {
    const streams = section.streams || [];
    return streams.map((name) => {
      return mapTagToList(name, pageId, playlists, tracks, tags);
    });
  }
);
export const selectTeamStream = createSelector(
  [selectSection, selectCurrentTeamStream, selectPageId, playlists, tracks, tags],
  (section, selectedTeamStream, pageId, playlists, tracks, tags) => {
    return mapTagToList(selectedTeamStream, pageId, playlists, tracks, tags, section);
  }
);

export const selectSpecificPlaylist = (state, listId) => state.playlists[listId] || [];
export const selectEditorialPlaylist = (state, listId) =>
  selectSpecificPlaylist(state, `${selectPageId(state)}-${listId}`);

export const currentFeaturedPlaylist = createSelector(
  [selectSection, playlists],
  (section, playlists) => playlists[section.featured] || []
);
export const currentHeadlinesPlaylist = createSelector(
  [selectSection, playlists],
  (section, playlists) => playlists[section.headlines] || []
);
export const currentTrendingPlaylist = createSelector(
  [selectSection, playlists],
  (section, playlists) => playlists[section.trending] || []
);
export const currentEvergreenPlaylist = createSelector(
  [selectSection, playlists],
  (section, playlists) => playlists[section.evergreen] || []
);

function extractTracksFromPlaylist(playlist, tracks) {
  return playlist.reduce((output, id) => {
    return tracks[id] ? output.concat(tracks[id]) : output;
  }, []);
}

export const currentFeaturedTracks = createSelector(
  [currentFeaturedPlaylist, tracks],
  extractTracksFromPlaylist
);
export const currentHeadlinesTracks = createSelector(
  [currentHeadlinesPlaylist, tracks],
  extractTracksFromPlaylist
);
export const currentTrendingTracks = createSelector(
  [currentTrendingPlaylist, tracks],
  extractTracksFromPlaylist
);
export const currentEvergreenTracks = createSelector(
  [currentEvergreenPlaylist, tracks],
  extractTracksFromPlaylist
);

export const currentEditorialPlaylists = createSelector(
  [
    currentFeaturedPlaylist,
    currentHeadlinesPlaylist,
    currentTrendingPlaylist,
    currentEvergreenPlaylist,
  ],
  (featured, headlines, trending, evergreen) => {
    return {
      featured,
      headlines,
      trending,
      evergreen,
    };
  }
);

export const makeSelectEditorialTracks = (editorialList) => {
  // because otherwise it won't memoize, so we need to create specific ones.
  const editorialTrackSelectorInstance = createSelector(
    [selectEditorialPlaylist, tracks],
    (playlist, tracks) => {
      return playlist.reduce((trackList, trackId) => {
        if (tracks[trackId]) {
          trackList.push(tracks[trackId]);
        }
        return trackList;
      }, []);
    }
  );
  return (state) => {
    return editorialTrackSelectorInstance(state, editorialList);
  };
};

const selectSubNav = (state) => state.nav.subnav;

const teamGames = (state) => state.sections.teamGames;

const selectTags = (state) => state.tags;

export const selectTicketsLink = createSelector(selectSubNav, (subnav) => {
  if (!subnav || !subnav.length) {
    return false;
  }
  const ticketsLink = subnav.find((link) => link && link.title === 'Tickets' && link.url);
  return ticketsLink ? ticketsLink.url : false;
});

export const selectTicketsImage = createSelector(selectTeamLeague, (name) =>
  ImagesAPI.ticketImage(name)
);

export const selectTickets = createSelector(selectTicketsLink, (url) => {
  return url;
});

export const selectTeamSection = createSelector(
  [selectTags, selectPageId],
  (tags, currentSection) => tags[currentSection]?.type === 'Team'
);

const getNextUpcomingGame = (games, season) => {
  const todayDate = new Date();
  let upcomingGame;
  games?.find(
    (game) =>
      (upcomingGame = game?.entries.find((gameEntry) => {
        if (todayDate < new Date(gameEntry?.iso_utc_datetime) && gameEntry?.ticket_info) {
          return gameEntry;
        }
      }))
  );
  return {
    game: upcomingGame,
    season,
  };
};

export const selectNextUpcomingGame = createSelector(teamGames, ({games, season}) => {
  const currentSeason = new Date().getFullYear();
  if (currentSeason > season) {
    return;
  }
  return getNextUpcomingGame(games, season);
});
