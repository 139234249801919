import React from 'react';

interface CloseIconProps {
  classnames?: string;
}

export const CloseIcon = ({classnames}: CloseIconProps) => {
  return (
    <svg
      className={classnames}
      width="30"
      height="28"
      viewBox="0 0 30 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.8343 14.1753L0.431882 2.86213L2.61328 0.872314L15.0157 12.1855L27.3868 0.900917L29.5682 2.89073L17.1971 14.1753L30.0001 25.8539L27.8187 27.8437L15.0157 16.1651L2.1814 27.8723L0 25.8825L12.8343 14.1753Z"
        fill="white"
      />
    </svg>
  );
};
