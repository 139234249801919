import {BR, Layser} from '../endpoints';
import {createFetchService} from './fetch_utils';
import {isGameStream} from '../helpers/streamHelpers';

const fetchJSON = createFetchService('LayserBeam');
const postJSON = createFetchService('LayserBeam', {method: 'POST'});

export const emptyTrackData = {tracks: []};

function append_ts(data) {
  return data.slice(-3) === '_ts' ? data : `${data}_ts`;
}

function errorHandler(err) {
  return {
    error: err,
  };
}

function extractStreams(data) {
  return data.streams || data;
}

export function stream(tag, limit, options = {}) {
  if (tag) {
    const url = Layser.playlist(tag, limit, options.isFullPlaylistSlug);
    const visitorCountryCode = options.visitorCountryCode;
    const fetchOptions = {
      headers: {},
    };

    // This will ask Layserbeam's stream API for $...metadata.creators data
    if (process.env.CREATORS_ENABLED === 'true') {
      fetchOptions.headers['Accept'] = 'application/vnd.app.v2+json';
    }

    if (visitorCountryCode) {
      fetchOptions.headers['geo-country-code'] = visitorCountryCode;
    }

    return fetchJSON(url, fetchOptions)
      .then(extractStreams)
      .catch(() => emptyTrackData);
  }
  return Promise.resolve(emptyTrackData);
}

export function fetchGamecastStream(path, country) {
  if (path) {
    const url = Layser.gamecastPlaylist(path);
    const headers = {
      Accept: 'application/vnd.app.v3+json',
    };

    if (country) {
      headers['geo-country-code'] = country;
    }

    const fetchOptions = {
      headers,
    };

    return fetchJSON(url, fetchOptions)
      .then(extractStreams)
      .catch(() => emptyTrackData);
  }
  return Promise.resolve(emptyTrackData);
}

function section(section, userTags = [], options = {}) {
  const visitorCountryCode = options.visitorCountryCode;
  if (section) {
    return fetchJSON(Layser.pageSection(section), {
      headers: {['geo-country-code']: visitorCountryCode},
    })
      .then((data) => {
        const streamList =
          section === 'front-page' || section === 'uk'
            ? [...data.streams, ...userTags]
            : data.streams;
        // When fetching streams if there is more than one we need to use the _ts feed (direct programmed content).
        // Otherwise it won't blend the streams (in fact it'll just error on the djay side).
        const streams = streamList.length === 1 ? streamList : streamList.map(append_ts).join();
        return stream(streams, 60, {visitorCountryCode}).then((streamData) => {
          const {id, metadata_type: type, tracks} = streamData;
          if (isGameStream(type)) {
            return {
              ...data,
              playlistId: id,
              tracks,
              type,
            };
          }
          return {
            ...data,
            tracks,
          };
        });
      })
      .catch(errorHandler);
  }
  return Promise.resolve(emptyTrackData);
}

function trending(section, limit) {
  if (section) {
    const params = {
      limit,
    };
    return fetchJSON(Layser.playlistSectionTrending(section, params))
      .then((data) => data)
      .catch(() => emptyTrackData);
  }
  return Promise.resolve(emptyTrackData);
}

const fetchStub = (url) => {
  // Strips off everything after the first URL-encoded character (ie "%2F" etc) as we don't support them.
  const parsedUrl = url.replace(/%.*/, '');
  return fetchJSON(Layser.stub(parsedUrl)).catch(errorHandler);
};

const fetchAggregateCountsForUserPost = (userPostId) => {
  return fetchJSON(Layser.aggregateCountsForUserPost(userPostId)).catch(errorHandler);
};

export const getGamecast = (slug, countryCode, regionCode) => {
  return fetchJSON(Layser.gamecast(slug), {
    headers: {
      Accept: 'application/vnd.app.v3+json',
      ...(countryCode && {'geo-country-code': countryCode}),
      ...(regionCode && {'geo-region-code': regionCode}),
    },
  }).catch(errorHandler);
};

function reverseLookup(url) {
  return fetchJSON(Layser.reverseLookup(url))
    .then(({tracks = []}) => tracks)
    .catch(() => []);
}

function reverseLookupArticle(permalink) {
  return reverseLookup(BR.article(permalink));
}

/**
 * Fetch comment data for a track
 * @param {string} contentHash This is the `url_hash` property of the track
 * @param {string} objectType  Defaults to `track`
 */
function fetchCommentData(contentHash, objectType = 'track') {
  const requestOptions = {
    body: {
      objects: [
        {
          content_hash: contentHash,
          object_type: objectType,
        },
      ],
    },
    headers: {
      Accept: 'application/vnd.app.v2+json',
    },
    method: 'post',
  };
  return fetchJSON(Layser.social().reactionAggregatesAnon, requestOptions)
    .then(({objects = []}) => (objects.length > 0 ? objects[0] : {}))
    .catch(() => ({}));
}

/**
 * Fetch Tag Alerts
 * TagNames could be a single tag or a Comma delimited list of tags
 * @param {string} tagNames
 */
function fetchAlerts(tagNames) {
  if (!tagNames) {
    return Promise.reject('Cannot fetch alerts without tagNames');
  }
  return fetchJSON(Layser.alerts(tagNames))
    .then((data) => data)
    .catch(errorHandler);
}

const fetchVideoRecommendations = (articleId) => {
  if (articleId) {
    return fetchJSON(Layser.videoRecommendations(articleId)).catch(errorHandler);
  }
  return Promise.reject(new Error('No article ID passed to Layser API'));
};
const fetchTrendingVideos = () => {
  return fetchJSON(Layser.trendingVideos()).catch(errorHandler);
};
const fetchArticle = (articleID) => {
  if (articleID) {
    return fetchJSON(Layser.article(articleID)).catch(errorHandler);
  }
  return Promise.reject(new Error('No Article ID passed to Articles API'));
};
const fetchRecommendedArticles = (articleId) => {
  if (articleId) {
    return fetchJSON(Layser.recommendedArticles(articleId)).catch(errorHandler);
  }
  return Promise.reject(new Error('No article ID passed to Layser API'));
};
const fetchTrendingArticles = () => {
  return fetchJSON(Layser.trendingArticles()).catch(errorHandler);
};
const newsletterSubscription = (emailAddress) => {
  if (emailAddress) {
    const xTokenHeader = "I'm on the mound, G, and it's a no-hitter";
    return postJSON(Layser.newsletterSubscription(), {
      body: {subscribed_email: emailAddress},
      headers: {'x-token': xTokenHeader},
    })
      .then((data) => data)
      .catch(errorHandler);
  }
  return Promise.reject(new Error('No email address passed to Layser API'));
};

const fetchMyEvents = (productIds) => {
  return postJSON(Layser.myEvents(), {
    body: {
      product_ids: productIds,
    },
    headers: {},
  })
    .then((data) => data)
    .catch(errorHandler);
};

export default {
  fetchAggregateCountsForUserPost,
  fetchAlerts,
  fetchArticle,
  fetchCommentData,
  fetchMyEvents,
  fetchRecommendedArticles,
  fetchStub,
  fetchTrendingArticles,
  fetchTrendingVideos,
  fetchVideoRecommendations,
  getGamecast,
  newsletterSubscription,
  reverseLookup,
  reverseLookupArticle,
  section,
  stream,
  trending,
};
