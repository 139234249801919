import React, {useState} from 'react';
import {Typography, Variant} from '../../../Shared/Typography/Typography';
import {Color} from '../../../types';
import {NavLink} from '../../../Shared/NavLink/NavLink';
import {NavBarLink, DropdownType, TeamsDropdown} from '../../types';
import {Scroll, ScrollDirection} from '../../../Shared/Scroll/Scroll';
import {Tabs} from '../../../Shared/Tabs/Tabs';
import {Tab} from '../../../Shared/Tab/Tab';
import {DivisionDropdown} from '../../DivisionDropdown/DivisionDropdown';
import {LeagueDropdown} from '../../LeagueDropdown/LeagueDropdown';
import {Position, Drawer} from '../../../Shared/Drawer/Drawer';

export interface MobileSubNavBarProps {
  href: string;
  links: NavBarLink[];
  title: string;
  value?: string;
  variant?: Variant;
  color?: Color;
}

export const MobileSubNavBar: React.FC<MobileSubNavBarProps> = ({
  href,
  links,
  title,
  value,
  variant = Variant.H4,
  color = Color.Tertiary,
}) => {
  // eslint-disable-next-line no-use-before-define
  const [subNavDropdown, setSubNavDropdown] = useState(null);
  const subLinks = subNavDropdown?.children;

  function handleOnClick(link: NavBarLink, event: React.MouseEvent) {
    event.stopPropagation();
    // If a dropdown is currently open and the user has tapped on the parent link, we want to close the dropdown
    if (link.title === value && subLinks) {
      setSubNavDropdown(null);
    } else {
      setSubNavDropdown(link);
    }
  }

  return (
    <div className="navBarFonts mobileSubNavBar">
      <div className="mobileSubNavBar__titleWrapper">
        <a href={href}>
          <Typography variant={Variant.H3} className="mobileSubNavBar__title">
            {title}
          </Typography>
        </a>
      </div>
      <div className="mobileSubNavBar__tabsContainer">
        <Scroll scrollDirection={ScrollDirection.Horizontal} hideScrollbar={true}>
          <Tabs className="mobileSubNavBar__tabs" value={subNavDropdown?.title || value}>
            {links?.map((link) => (
              <Tab key={link.title} value={link.title}>
                <NavLink
                  href={link.href}
                  variant={variant}
                  color={color}
                  // eslint-disable-next-line react/jsx-no-bind
                  onClick={(event) => handleOnClick(link, event as React.MouseEvent)}
                >
                  {link.title}
                </NavLink>
              </Tab>
            ))}
          </Tabs>
        </Scroll>
      </div>

      <div className="mobileSubNavBar__drawerContainer">
        <Drawer
          open={!!subLinks}
          position={Position.Top}
          // setTimeout is to ensure that the subNavDropdown is not null before 'handleOnClick' has done its stuff
          // eslint-disable-next-line react/jsx-no-bind
          onClose={() => setTimeout(() => setSubNavDropdown(null), 0)}
        >
          <hr className="mobileNavBar__subNavBorder" />
          {subLinks?.type === DropdownType.Division && (
            <DivisionDropdown
              divisions={(subLinks as TeamsDropdown)?.sections}
              hideScrollbar={true}
            />
          )}

          {subLinks?.type === DropdownType.League && (
            <LeagueDropdown leagues={(subLinks as TeamsDropdown)?.sections} hideScrollbar={true} />
          )}
        </Drawer>
      </div>
    </div>
  );
};
