import classnames from 'classnames';
import React, {ReactElement} from 'react';
import {Typography, Variant} from '../Typography/Typography';
import {Color} from '../../types';

export interface NavLinkProps {
  variant?: Variant;
  color?: Color;
  href?: string;
  isDisabled?: boolean;
  children?: React.ReactNode;
  className?: string;
  onMouseEnter?: React.MouseEventHandler;
  onMouseLeave?: React.MouseEventHandler;
  onClick?: React.MouseEventHandler;
}

export const NavLink: React.FC<NavLinkProps> = ({
  variant,
  color = Color.Disabled,
  href,
  isDisabled,
  children,
  className,
  onMouseEnter,
  onMouseLeave,
  onClick,
}) => {
  const classes = classnames({
    navLink: true,
    [`-disabled`]: isDisabled || !href,
    [`${className}`]: className,
  });
  return (
    <Typography
      variant={variant}
      color={color}
      element="a"
      href={href}
      className={classes}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
    >
      {children}
    </Typography>
  );
};
