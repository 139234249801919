import React from 'react';
import {NavBarLink} from '../../types';
import {NavLink} from '../../../Shared/NavLink/NavLink';
import {Variant} from '../../../Shared/Typography/Typography';
import {Color} from '../../../types';
import {Scroll, ScrollDirection} from '../../../Shared/Scroll/Scroll';
import classnames from 'classnames';
import {useViewportHeight} from '../../useViewportHeight';

export interface MobileSideMenuProps {
  links: NavBarLink[];
  variant?: Variant;
  color?: Color;
  onClick: (item: NavBarLink) => void;
  wunderkindItem?: boolean;
}

export const MobileSideMenu: React.FC<MobileSideMenuProps> = ({
  links,
  variant = Variant.H2,
  color = Color.Primary,
  onClick,
  wunderkindItem,
}) => {
  const fullViewportHeight = useViewportHeight();
  return (
    <Scroll
      scrollDirection={ScrollDirection.Vertical}
      className="mobileSideMenu"
      style={{height: `calc(${fullViewportHeight}px - var(--br-navbar_height))`}}
    >
      {links?.map((link, index: number) => {
        return (
          <NavLink
            key={link.title}
            href={link.href}
            variant={variant}
            color={color}
            className={classnames({mobileSideMenu__item: true, '-first': index === 0})}
            // eslint-disable-next-line react/jsx-no-bind
            onClick={() => onClick(link)}
          >
            {link.title}
          </NavLink>
        );
      })}

      {wunderkindItem && <div className="mobileSideMenu__wunderkindPlaceholder" />}
    </Scroll>
  );
};
