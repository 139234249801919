/**
 * Scripts with preload attribute will take precedence over other scripts
 */
const scripts = [
  {
    key: 'rwxsawuta',
    src: '//d1je72vo6cxqo9.cloudfront.net/script.js',
    preload: true,
  },
];

export default scripts;
