import React, {createRef} from 'react';
import loadable from '@loadable/component';
import classnames from 'classnames';
import Advert from '../atoms/advert';
import Footer from '../molecules/footer';
import MobileInterstitial from '../molecules/mobileInterstitial';
import MobileWebAppPromo from '../molecules/mobileWebAppPromo';
import Notification from '../molecules/notification';
import {NavBar} from '../../components_v2/NavBar/NavBar';
import SmartBanner from '../molecules/smartBanner';
import TagManager from '../atoms/tagManager';
import {GAMECAST, SECTION, ARTICLE, LIVE_EVENTS} from '../../constants/pageTypes';
import Page from '../page';
import ScoreCardCarousel from '../molecules/scoreCardCarousel';
import UserConsent from '../atoms/userConsent';
import {prepareTveAuth} from '../molecules/video/tve/tveConfig';
import {HOME_PAGE} from '../../constants/tags';
// These components are ideal for loadable because of the condition.
const AndroidTsPromo = loadable(() => import('../atoms/androidTsPromo'));
const FollowSocial = loadable(() => import('../atoms/followSocial'));
const InstallApp = loadable(() => import('../atoms/installApp'));
const StickyAdvert = loadable(() => import('../atoms/stickyAdvert'));

class Website extends React.PureComponent {
  componentDidMount() {
    if (this.props.ui.showTve) {
      this.authBlockRef = createRef(null);
      prepareTveAuth({
        authBlockRef: this.authBlockRef,
        setTveAuthContext: this.props.setTveAuthContext,
        setTveProviderLogo: this.props.setTveProviderLogo,
      });
    }
  }
  buildAndroidTsFlyin(promoData) {
    return (
      <AndroidTsPromo
        addScript={this.props.addScript}
        closeClicked={this.props.onCloseFlyin}
        installLink={this.props.appInstallLink}
        onHideFlyin={this.props.onHideFlyin}
        onShowFlyin={this.props.onShowFlyin}
        page={this.props.page}
        tags={this.props.tags}
        trackEvent={this.props.trackEvent}
        userClosed={promoData.userClosed}
        visibility={promoData.visibility}
      />
    );
  }

  buildFollowSocialFlyin(promoData, campaign) {
    return (
      <FollowSocial
        {...promoData.data}
        campaign={campaign}
        closeClicked={this.props.onCloseFlyin}
        onHideFlyin={this.props.onHideFlyin}
        onShowFlyin={this.props.onShowFlyin}
        userClosed={promoData.userClosed}
        visibility={promoData.visibility}
      />
    );
  }

  buildInstallAppFlyin(promoData) {
    return (
      <InstallApp
        {...promoData.data}
        closeClicked={this.props.onCloseFlyin}
        handleSubmit={this.props.onSendToPhone}
        onChange={this.props.onChangeContactInfo}
        onHideFlyin={this.props.onHideFlyin}
        onShowFlyin={this.props.onShowFlyin}
        userClosed={promoData.userClosed}
        visibility={promoData.visibility}
      />
    );
  }

  render() {
    const classes = classnames({
      template: true,
      website: true,
      [this.props.ui.theme]: !!this.props.ui.theme,
    });
    const showStickyAd =
      (this.props.page.type === ARTICLE && this.props.page.render_strategy !== 'slideshow') ||
      this.props.page.type === SECTION;
    const stickyAd = showStickyAd && (
      <StickyAdvert
        position="bnr_atf_05"
        id="ba5"
        isMobileDevice={this.props.ui.isMobileDevice}
        country={this.props.user.country}
        responsiveSize="sticky"
        reload={2}
        closeButton={true}
      />
    );
    const topAd = this.props.ui.topAd_exists ? (
      <Advert
        id="1"
        country={this.props.user.country}
        isVisitorOnMobile={this.props.ui.isMobileDevice}
        position="bnr_atf_01"
        responsiveSize="wide"
        size={[
          [320, 50],
          [320, 1],
        ]}
        reload={2}
      />
    ) : (
      false
    );
    const skinAd = this.props.ui.skinAd_exists ? (
      <Advert
        id="5"
        country={this.props.user.country}
        isVisitorOnMobile={this.props.ui.isMobileDevice}
        position="oop_skin_01"
        reload="1"
        responsiveSize="skin"
        size={[[3, 3]]}
      />
    ) : (
      false
    );
    const androidFlyin =
      this.props.promos && this.props.promos.androidTsPromo
        ? this.buildAndroidTsFlyin(this.props.promos.androidTsPromo)
        : false;
    const facebookFlyin =
      this.props.promos && this.props.promos.facebook
        ? this.buildFollowSocialFlyin(this.props.promos.facebook, 'facebook')
        : false;
    const installAppFlyin =
      this.props.page.type !== ARTICLE && this.props.promos && this.props.promos.send2phone
        ? this.buildInstallAppFlyin(this.props.promos.send2phone)
        : false;
    const twitterFlyin =
      this.props.promos && this.props.promos.twitter
        ? this.buildFollowSocialFlyin(this.props.promos.twitter, 'twitter')
        : false;

    const adPlacement =
      this.props.ui.adTest && process.env.AD_TEST ? process.env.AD_TEST || 'below' : 'below';
    //Top Banner will be shown inside the contentStream if the page is an article and the device is mobile
    const brArticle = this.props.page.type === ARTICLE && this.props.ui.isMobileDevice;
    const carouselPageTypes = [ARTICLE, SECTION, GAMECAST];
    const isLiveEventsPage = this.props.page.type === LIVE_EVENTS;
    return (
      <div className={classes}>
        <UserConsent
          addScript={this.props.addScript}
          oneTrust={this.props.ui.oneTrust}
          countryCode={this.props.user.country}
        />
        <TagManager addScript={this.props.addScript} />
        <div className="fixedRegion">
          <div className="fixedBottom">
            {stickyAd}
            <Notification
              {...this.props.notificationCallbacks}
              data={this.props.notificationsData}
              deviceFamily={this.props.deviceFamily}
              name="smart_banner"
              ui={this.props.ui}
            >
              <SmartBanner
                displayTag={this.props.displayTag}
                onMount={this.props.onSmartBannerMounted}
                teamStream={this.props.teamStream}
                trackEvent={this.props.trackEvent}
                {...this.props.promos.smartBanner}
                {...this.props.notificationCallbacks}
              />
            </Notification>
            <Notification
              checkNotification={this.props.notificationCallbacks.checkNotification}
              onCloseNotification={this.props.notificationCallbacks.onCloseNotification}
              data={this.props.notificationsData}
              name="mobile_web_app_promo"
              ui={this.props.ui}
            >
              <MobileWebAppPromo
                data={this.props.promos.mobileWebAppPromo}
                onCloseNotification={this.props.notificationCallbacks.onCloseNotification}
                onMount={this.props.promoCallbacks.mobileWebAppPromoMounted}
                displayTag={this.props.displayTag}
                trackEvent={this.props.trackEvent}
              />
            </Notification>
            {adPlacement === 'above' && !isLiveEventsPage && topAd}
          </div>
        </div>
        {/* This is for the purpose of hiding navbar on mobile when embedding article page  */}
        {!this.props.isHidden.nav && (
          <header className="NavBarContainer">
            <NavBar
              injectH1={this.props.page.id === HOME_PAGE && true}
              navigation={this.props.nav?.navigation}
              path={this.props.nav?.path}
              userType={this.props.user.type}
              profileId={this.props.user.profile_id}
            />
          </header>
        )}
        <div id="main">
          <Notification
            {...this.props.notificationCallbacks}
            data={this.props.notificationsData}
            name="mobile_interstitial"
            ui={this.props.ui}
          >
            <MobileInterstitial
              appInstallLink={this.props.notificationsData.interstitialInstallLink}
              page={this.props.page}
              ui={this.props.ui}
              tags={this.props.tags}
              displayTag={this.props.displayTag}
              customBrowser={this.props.ui.customBrowser}
              leadImage={this.props.leadImage}
              os={this.props.ui.os}
              trackEvent={this.props.trackEvent}
              {...this.props.notificationCallbacks}
            />
          </Notification>
          {!this.props.page?.embedded &&
            this.props.ui.showScoreCardCarousel &&
            carouselPageTypes.includes(this.props.page.type) && (
              <ScoreCardCarousel isMobile={this.props.ui.isMobileDevice} />
            )}

          <div
            id="outer-container"
            className={classnames({
              'has-sticky-el': this.props.page.type === GAMECAST,
            })}
          >
            {!isLiveEventsPage && skinAd}
            {adPlacement === 'below' && !brArticle && !isLiveEventsPage && topAd}
            <div id="main-content">
              <div id="ad-skin-measurability-l" className="ad-rail" />
              <Page
                isBrMag={this.props.article.br_mag}
                pageType={this.props.page.type}
                store={this.props.store}
                tveAuthContext={this.props.ui.tveAuthContext}
                setTveAuthContext={this.props.setTveAuthContext}
                setTveProviderLogo={this.props.setTveProviderLogo}
                tveProviderLogo={this.props.ui.tveProviderLogo}
                showTve={this.props.ui.showTve}
              />
              <div id="ad-skin-measurability-r" className="ad-rail" />
            </div>
            {androidFlyin}
            {facebookFlyin}
            {installAppFlyin}
            {twitterFlyin}
            <div className="adsContainer" />
            <Footer
              addScript={this.props.addScript}
              hideGettyCopyright={this.props.isHidden.gettyCopyright}
              hide={this.props.isHidden.footer}
              hideFooterLinks={this.props.isHidden.footerLinks}
              assetURL={this.props.assetURL}
              pageType={this.props.page.type}
              brHost={this.props.ui.brHost}
              user={this.props.user}
              ui={this.props.ui}
              showNewsletterSubsModule={this.props.ui.showNewsletterSubsModule}
            />
          </div>
        </div>
      </div>
    );
  }
}

Website.defaultProps = {
  user: {},
  nav: {},
  assetURL: '',
  teamStream: {
    info: {},
  },
  ui: {
    oneTrust: {},
  },
};

export default Website;
