import React from 'react';
import classnames from 'classnames';

export const HamburgerIcon = () => {
  const classes = classnames({
    hamburgerIcon: true,
  });

  return (
    <svg className={classes} viewBox="0 0 32 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 2.9739H31.167V0H0V2.9739ZM0 12.4876H31.167V9.51367H0V12.4876ZM31.167 21.9993H0V19.0254H31.167V21.9993Z"
        fill="white"
      />
    </svg>
  );
};
