import React from 'react';
import classnames from 'classnames';

export const DefaultUserIcon = () => {
  const classes = classnames({
    defaultUserIcon: true,
  });

  return (
    <svg className={classes} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
      <circle fill="#fff" cx="50" cy="50" r="50" />
      <g fill="#000" transform="translate(20 15)">
        <path d="M0 60.617C7.165 69.407 17.945 75 30 75s22.835-5.593 30-14.383C54.197 51.31 42.938 45 30 45 17.062 45 5.803 51.31 0 60.617z" />
        <circle cx="30" cy="20" r="20" />
      </g>
    </svg>
  );
};
