import React, {
  ReactNode,
  useState,
  useEffect,
  useCallback,
  Children,
  useRef,
  ReactElement,
} from 'react';

export interface TabsProps {
  children: ReactNode;
  value: string;
  className?: string;
  style?: React.CSSProperties;
  onMouseEnter?: React.MouseEventHandler;
  onMouseLeave?: React.MouseEventHandler;
}

export const Tabs: React.FC<TabsProps> = ({
  children,
  value,
  className,
  style,
  onMouseLeave,
  onMouseEnter,
}) => {
  /*
    resizeObserver: Necessary cause children might change their dimension and the indicator would need to
    resize/reposition itself based on those new dimensions. (Helps with initial render since initial dimensions change after component is mounted).
  */
  const containerRef = useRef();
  // eslint-disable-next-line no-use-before-define
  const [indicatorStyle, setIndicatorStyle] = useState<React.CSSProperties>(indicatorInitializer);

  const repositionIndicator = useCallback(() => {
    // eslint-disable-next-line no-use-before-define
    const coordinates = getCoordinates(getChildIndexByValue(children, value));
    if (coordinates) {
      setIndicatorStyle({...coordinates, opacity: 1});
    } else {
      setIndicatorStyle((style) => ({...style, opacity: 0}));
    }
  }, [children, value]);

  useEffect(() => {
    const observer = new ResizeObserver(repositionIndicator);
    observer.observe(containerRef.current);

    return () => observer.disconnect();
  }, [repositionIndicator]);

  function getChildIndexByValue(children: ReactNode, filterValue: string): number {
    if (!filterValue) {
      return -1;
    }

    const index = Children.toArray(children).findIndex((item: ReactElement) => {
      return item.props.value?.toLowerCase() === filterValue?.toLowerCase();
    });

    if (index < 0) {
      global.console.warn('Children must have property: value:string');
    }

    return index;
  }

  function getCoordinates(childIndex: number): React.CSSProperties | null {
    const container = containerRef?.current as HTMLElement;
    const tab = container?.children?.item(childIndex);

    if (tab) {
      const parent = container.getBoundingClientRect();
      const target = tab.getBoundingClientRect();
      const leftOffset = target.left - parent.left;
      return {width: target.width, left: leftOffset};
    }

    return null;
  }

  function indicatorInitializer() {
    return getCoordinates(getChildIndexByValue(children, value));
  }

  function handleTransitionEnd() {
    if (indicatorStyle.opacity === 0) {
      // remove width and left values (opacity persist)
      // This allow the indicator to appear without sliding into the focus element the next time the opacity change to 1
      setIndicatorStyle({opacity: 0});
    }
  }

  return (
    <div className={className}>
      <div
        className="tabs"
        style={style}
        ref={containerRef}
        onMouseLeave={onMouseLeave}
        onMouseEnter={onMouseEnter}
      >
        {children}
      </div>

      <div className="tabs__track">
        <div
          className="tabs__indicator"
          style={indicatorStyle}
          onTransitionEnd={handleTransitionEnd}
        />
      </div>
    </div>
  );
};
