export interface NavBarLink {
  title: string;
  href?: string;
  icon?: string;
  // this is a recursive interface
  // eslint-disable-next-line no-use-before-define
  children?: DefaultDropdown | TeamsDropdown;
}

export enum DropdownType {
  Division = 'division',
  League = 'league',
  Default = 'default',
}

export interface DefaultDropdown {
  type: DropdownType;
  links: NavBarLink[];
}

export interface TeamsDropdown {
  type: DropdownType;
  sections: {
    name: string;
    teams: NavBarLink[];
  }[];
}

export interface Navigation {
  fallback?: boolean;
  mainNavigation: {
    links: NavBarLink[];
  };
  subNavigation?: {
    title: string;
    links: NavBarLink[];
    href: string;
  };
}

export interface DropdownData {
  name: string;
  teams: NavBarLink[];
}
