import React from 'react';
import PropTypes from 'prop-types';
import Overlay from '../../../atoms/overlay';

const noop = () => {};
const NCAARedirect = () =>
  (global.location.href = 'https://mml.smart.link/8wxyzml72?cid=mml_partner_br_generic');

export const getNCAAOverlayProps = () => {
  return {
    text:
      'Your free preview has ended.\n For more live action, visit NCAA.com to keep streaming March Madness games.',
    secondaryText: (
      <button className="ncaa-link" onClick={NCAARedirect}>
        GO NOW
      </button>
    ),
    classes: 'free-preview-ended',
  };
};

export const getOverlayProps = (onClick) => {
  return {
    text: 'Your preview has ended. Sign in with your TV provider to keep watching the event.',
    secondaryText: (
      <button className="sign-in" onClick={onClick}>
        Sign In to TV Provider
      </button>
    ),
    classes: 'free-preview-ended',
  };
};

export const TveFreePreviewEndedOverlay = ({authInitialized, onTveLogin}) => {
  const onclick = authInitialized ? onTveLogin : noop;
  const props = getOverlayProps(onclick);

  return (
    <div>
      <Overlay {...getOverlayProps(props)} />
    </div>
  );
};

TveFreePreviewEndedOverlay.propTypes = {
  authInitialized: PropTypes.bool,
  onTveLogin: PropTypes.func,
  posterImage: PropTypes.string,
};

export default TveFreePreviewEndedOverlay;
