import React from 'react';
import classnames from 'classnames';
import {SVG} from '@br/br-components';

import {SOCIAL_LINKS} from '../../constants/socialLinks';
import {WarnerMedia} from '../../endpoints';

const currentYear = new Date().getFullYear();
const standard = `
  Copyright © ${currentYear} Bleacher Report, Inc. Turner Broadcasting System, Inc.
  All Rights Reserved.
  BleacherReport.com is part of Bleacher Report – Turner Sports Network, part of the Turner Sports and Entertainment Network.
`;
const getty = `
  Certain photos copyright © ${currentYear} Getty Images.
  Any commercial use or distribution without the express written consent of Getty Images is strictly prohibited.
`;

function generateCopyrightMessages(showGetty) {
  const messages = {
    standard,
    getty: false,
  };
  if (showGetty) {
    return {
      ...messages,
      getty,
    };
  }
  return messages;
}

class Footer extends React.Component {
  constructor(props) {
    super(props);
  }

  shouldComponentUpdate() {
    return false; // should NEVER need to update.
  }

  showCookiePreferenceCenter() {
    const linkAction = global.WM.UserConsent.getLinkAction();

    linkAction();
  }

  render() {
    if (this.props.hide) {
      return false;
    }
    const {siteLocale} = this.props.user;
    const socialLinks = siteLocale === 'USA' ? SOCIAL_LINKS.US : SOCIAL_LINKS.UK;

    const copyrightMessages = generateCopyrightMessages(!this.props.hideGettyCopyright);
    const classes = classnames({
      molecule: true,
      footer: true,
    });
    const adChoicesLink = (
      <a
        href="https://www.warnermediaprivacy.com/policycenter/b2c/WM/"
        target="_blank"
        rel="noopener noreferrer"
      >
        AdChoices
      </a>
    );
    const isUSuser = this.props.ui.countryCode === 'US';
    const manageCookiePreferencesLink = (
      <a href="#" onClick={this.showCookiePreferenceCenter}>
        {isUSuser ? 'Do Not Sell or Share My Personal Information' : 'Cookie Preferences'}
      </a>
    );

    return (
      <footer className={classes}>
        {!this.props.hideFooterLinks ? (
          <div>
            <SVG type="logo" target="brPlateLight" />
            {/** Wunderkind depends on this DOM element in order to inject its custom campaign */}
            <div className="footer newsletterSubsModule" />
            <ul className="social">
              <li>
                <a href={socialLinks.FB}>
                  <SVG type="icon" target="facebookFilled" />
                </a>
              </li>
              <li>
                <a href={socialLinks.TW}>
                  <SVG type="icon" target="twitter" />
                </a>
              </li>
              <li>
                <a href={socialLinks.IG}>
                  <SVG type="icon" target="instagram" />
                </a>
              </li>
            </ul>
            <ul className="links">
              <li>
                <a href={`${this.props.brHost}/pages/about`}>About</a>
              </li>
              <li>
                <a href={`${this.props.brHost}/pages/advertise`}>Advertise</a>
              </li>
              <li>
                <a href={`${this.props.brHost}/pages/contact`}>Contact Us</a>
              </li>
              <li>
                <a href="https://support.bleacherreport.com/">Get Help</a>
              </li>
              <li>
                <a href="https://careers.bleacherreport.com/">Careers</a>
              </li>
              <li>
                <a href={`${this.props.brHost}/sitemap`}>Sitemap</a>
              </li>
              <li>
                <a href={`${this.props.brHost}/pages/comguidelines`}>Community Guidelines</a>
              </li>
              <li>
                <a href={WarnerMedia.privacy()}>Privacy</a>
              </li>
              <li>{manageCookiePreferencesLink}</li>
              <li>
                <a href={`${this.props.brHost}/pages/terms`}>Terms Of Use</a>
              </li>
              <li>{adChoicesLink}</li>
            </ul>
          </div>
        ) : (
          false
        )}
        <small className="copyright">
          {copyrightMessages.standard}
          {copyrightMessages.getty}
          {adChoicesLink}
        </small>
        <SVG type="logo" target="turner" />
      </footer>
    );
  }
}

Footer.defaultProps = {
  addScript: () => {}, // stub
  assetURL: '',
  brHost: '',
};

export default Footer;
